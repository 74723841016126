import React, {ChangeEvent, Suspense, useMemo, useRef, useState} from 'react'
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import "./AppProfile.scss";
import { Canvas, useFrame, ThreeElements } from '@react-three/fiber'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { useLoader } from '@react-three/fiber'
import {Simulate} from "react-dom/test-utils";
import {Mesh, TextureLoader, Vector3, Vector4} from "three";
import {Environment, OrbitControls, useTexture} from "@react-three/drei";
import {MTLLoader} from "three/examples/jsm/loaders/MTLLoader";
import {text} from "stream/consumers";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

interface props{
    playerData :{
        username : string,
        token : string,
        uuid: string
    }
    setPlayerData: any
}

function AppProfile(props:props){
    const [isHideSkinModal, setIsHideSkinModal] = useState(false);
    const [isHideCapeModal, setIsHideCapeModal] = useState(false);



    interface ProfileData {
        url : string,
        digest : string,
        metadata: {
            model : string
        }
    }

    const instance = axios.create({
        baseURL: "https://diplom.foxworld.online/",
        timeout: 1000,
        headers: {
            "Access-Control-Allow-Origin": "*",
        }
    });

    function Box() {
        const mtl = useLoader(MTLLoader, 'https://diplom.foxworld.online/main/assets/get/alex.mtl')
        //const mtl = useLoader(MTLLoader, '/bugatti.mtl')
        //const colorMap = useTexture("https://diplom.foxworld.online/main/skins/get/steve.png")
        //const obj = useLoader(OBJLoader, 'https://diplom.foxworld.online/main/assets/get/alex.obj');
        const texture = useLoader(TextureLoader, 'https://diplom.foxworld.online/main/skins/get/Endienasg.png');

        const obj = useLoader(OBJLoader, "https://diplom.foxworld.online/main/assets/get/alex.obj", (mdl)=>{
            mtl.preload();
            //mtl.loadTexture("https://diplom.foxworld.online/main/skins/get/Endienasg.png")
            mdl.setMaterials(mtl)
        });
        return (
            <primitive object={obj}/>
        );
    }

    function setUserSkin(){

    }

    async function sendSkin({value} : any){
        if(value !== null){
            console.log(value)
            const formData = new FormData();
            formData.append("file", value[0])
            const response = await instance.post("https://diplom.foxworld.online/main/skins/add/" + props.playerData.username ,formData, {
                headers: {
                    "Content-Type":"multipart/form-data"
                },
                data : formData
            }).then((result) => {
                setIsHideSkinModal(false)
            });
        }else{
            setIsHideSkinModal(true)
        }
    }


    function SkinModal(){
        const handleClose = () => setIsHideSkinModal(false);
        const [inputFile, setInputFile] = useState(null);

        const handleChangeFile = (event: ChangeEvent<any>) => {
            setInputFile(event.target.files);
        }



        return (
            <Modal show={isHideSkinModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className={"text-orange"}>Смена скина</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formFileDisabled" className="mb-3">
                            <Form.Label className={"text-orange"}>Выбрать файл</Form.Label>
                            <Form.Control type="file" accept={".png"} required={true}
                                          onChange={(e) => {
                                              handleChangeFile(e)
                                          }} className={"text-orange"}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <button className={"btn btn-success"} onClick={()=>{sendSkin({value :inputFile})}}>
                        Сохранить
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

     function CapeModal(){
        const handleClose = () => setIsHideCapeModal(false);
        return (
                <Modal show={isHideCapeModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Cape</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
                    <Modal.Footer>
                        <button className={"secondary"} onClick={handleClose}>
                            Close
                        </button>
                        <button className={"primary"} onClick={handleClose}>
                            Save Changes
                        </button>
                    </Modal.Footer>
                </Modal>
        )
    }





    return (
        <>
            <div>
                <Container>
                    <div>
                        <Row className={"justify-content-center"}>
                            <Col md={6}>
                                <Canvas >
                                    <ambientLight intensity={1}/>
                                    <OrbitControls position={[5, 1, 5]} />
                                    <Suspense fallback={null}>
                                        <Box />
                                    </Suspense>
                                </Canvas>
                            </Col>
                            <Col>
                                <div>
                                    <h6 className={"text-orange"}>Ваш никнейм: {props.playerData.username}</h6>
                                    <Row>
                                        <button onClick={()=>setIsHideSkinModal(true)} className={"btn btn-outline-orange"}>Сменить скин</button>
                                        {/*<button onClick={()=>setIsHideCapeModal(false)} className={"btn btn-outline-orange"}>Сменить плащ</button>*/}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <CapeModal/>
                    <SkinModal/>
                </Container>
            </div>
        </>
    )
}
export default AppProfile;