export function WindowsIcon(){
    return(
       <>

           <svg width="96px" height="96px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
               <path fill="#444444" d="M13.821 15.761h-9.038v-7.747l9.038-1.291zM27.217 15.761h-12.185v-9.198l12.185-1.777zM13.821 16.81h-9.038v7.747l9.038 1.291zM27.217 16.81h-12.185v9.198l12.185 1.777z"></path>
           </svg>
       </>
    )
}
