import React, {useState} from 'react';
import logo from './logo.svg';
import './assets/App.scss';
import AppHeader from "./components/AppHeader/AppHeader";
import AppCarousel from "./components/AppCarousel/AppCarousel";
import About from "./components/About/About";
import AppFooter from "./components/AppFooter/AppFooter";
import AppProfile from "./components/AppProfile/AppProfile";

import {Container} from "react-bootstrap";

function App() {
    const [isAccountActive, setIsAccountActive] = useState(false);
    const [userData, setUserData] = useState({
        username : "",
        token : "",
        uuid: ""
    })
    const [isHideMain, setIsHideMain] = useState(false);
    const handleAccountActive = (childData : boolean)=>{
        setIsAccountActive(childData)
    }
    const handleUserData = (childData : {
        username : "",
        token : "",
        uuid: ""
    })=>{
        setUserData(childData)
    }

    const handleIsHideMain = (childData : boolean) =>{
        setIsHideMain(childData);
    }

    function HideMain(){
       if(isHideMain == true){
           return (<div>
               <AppProfile playerData={userData} setPlayerData={handleUserData}/>
           </div>)
       }else{
           return (<div>
                   <AppCarousel/>
                   {/*<About/>*/}
           </div>)
       }
    }

    return (
    <div className="App">
        <AppHeader isAccountActive={handleAccountActive} value={isAccountActive} userData={handleUserData} userDataValue={userData} handlerHideMain = {handleIsHideMain} hideMain = {isHideMain}/>
            <HideMain/>
        <AppFooter/>
    </div>
  );
}

export default App;
